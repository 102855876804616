import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Submit({ name, icon }) {
  return (
    <div className="submit">
      <button type="submit">
        <span>
          {icon && <FontAwesomeIcon icon={icon} size="lg" />}
          <span>{name}</span>
        </span>
      </button>
    </div>
  );
}

export default Submit;
