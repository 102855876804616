import { useState, useEffect } from "react";
import { COUNTRIES } from "../../utils/helpers";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";

const Contact = ({ update, errorsFromDonate }) => {
  const [names, setNames] = useState({
    intl: false,
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setErrors(errorsFromDonate);
  }, [errorsFromDonate]);

  const handleUpdate = (name, value) => {
    if (name === "intl") {
      setNames((names) => ({ ...names, intl: !names.intl }));
      update(name, !names.intl);
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      update(name, value ? value : "");
    }
  };

  return (
    <>
      <p className="line" />

      <h3>Your Contact Information</h3>

      <div className="two">
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fname"
            value={names.fname}
            update={handleUpdate}
            errors={errors}
            autocomplete="given-name"
          />
        </div>
        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="lname"
            value={names.lname}
            update={handleUpdate}
            errors={errors}
            autocomplete="family-name"
          />
        </div>
      </div>

      <div className="two">
        <div>
          <Input
            type="text"
            label="Email Address"
            req={true}
            name="email"
            value={names.email}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Phone"
            req={true}
            name="phone"
            value={names.phone}
            update={handleUpdate}
            errors={errors}
            autocomplete="tel"
          />
        </div>
      </div>

      <p className="line" />

      <h3>Your Mailing Address</h3>
      <div className="two">
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="address"
            value={names.address}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-line1"
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="address2"
            value={names.address2}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-line2"
          />
        </div>
      </div>
      <div className="three">
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="city"
            value={names.city}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-level2"
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="US State"
            req={true}
            name="st"
            value={names.st}
            update={handleUpdate}
            errors={errors}
            autocomplete="address-level1"
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip / Postal Code"
            req={true}
            name="zip"
            value={names.zip}
            update={handleUpdate}
            errors={errors}
            autocomplete="postal-code"
          />
        </div>
      </div>
      <div className="checkbox">
        <Checkbox
          name="intl"
          update={handleUpdate}
          note=""
          data={[
            {
              value: false,
              label: "My mailing address is outside the US.",
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.intl,
            };
          })}
        />
      </div>
      <div className={names.intl ? "one" : "hidden"}>
        <div>
          <Select
            label="Select country:"
            req={true}
            name="country"
            value=""
            data={COUNTRIES}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
    </>
  );
};

export default Contact;
