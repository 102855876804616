import { useState, useRef } from "react";
import { API_URL } from "../utils/helpers";

import Modal from "../components/Modal";
import InputUncontrolled from "../components/InputUncontrolled";
import Select from "../components/Select";
import Msg from "../components/Msg/index";
import Submit from "../components/Submit/index";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const programs = [
	{
		name: "Clinics Can Help – Cribs for Kids",
		description:
			"Clinics Can Help – Cribs for Kids is striving to provide 1,000 cribs for infants in Palm Beach County to ensure they have a safe space to sleep.",
		more: {
			type: "vimeo",
			id: "889942496",
		},
	},
	{
		name: "HaitiChildren",
		description:
			"HaitiChildren’s helps provide medical care, housing, food and more for sick, disabled, and abandoned children. With severe social unrest and insecurity throughout the country, HaitiChildren continues to push forward with their mission to help save these precious children.",
		more: {
			type: "yt",
			id: "yKM9hoVgOmU",
		},
	},
	{
		name: "Pediatrics at Jupiter Medical Center",
		description:
			"From newborns and toddlers to adolescents to teenagers, Jupiter Medical Center is proud to partner with Nicklaus Children’s Hospital to ensure your child receives the best possible pediatric care. Pediatric services in partnership with Nicklaus Children’s Hospital include De George Inpatient Units, Mastroianni Emergency Department, and De George Level II NICU",
		more: {
			type: "vimeo",
			id: "889941015",
		},
	},
	{
		name: "Mia’s Miracles",
		description:
			"Founded by Maria and Camilo Villegas in honor of their precious daughter, Mia who lost her battle with brain and spine cancer in 2020 at 22 months old. Mia’s Miracles provides small blessings with large impacts, bringing smiles and positivity to children and families facing challenging circumstances.",
		more: {
			type: "vimeo",
			id: "889939609",
		},
	},

	{
		name: "MiracleFeet",
		description:
			"2 million children live with the pain and stigma of untreated clubfoot, a condition that affects at least 1 in 800 globally. MiracleFeet is on a mission to create universal access to treatment for this leading cause of physical disability worldwide.",
		more: {
			type: "yt",
			id: "tL8ge38GklM",
		},
	},

	{
		name: "Nicklaus Children’s Hospital",
		description:
			"Nicklaus Children’s Hospital proudly serves as one of the few remaining standalone pediatric hospitals in the U.S. Touching the lives of nearly 1 million children each year, they remain dedicated to reach many more kids, both near and far.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/nch.pdf",
		},
	},
	{
		name: "Nicklaus Children’s Palm Beach Gardens Outpatient Center",
		description:
			"Located in Legacy Place in Palm Beach Gardens this center offers consultation, evaluation, and treatment services when your child needs non-emergency care. Services include Urgent Care, Diagnostics and Testing, Rehabilitation Services, and Pediatric Specialist Appointments.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/nch-pbg.pdf",
		},
	},
	{
		name: "Oliver Patch Project",
		description:
			"Oliver Patch Project’s mission is to create a FREE, fun, engaging platform where children with childhood cancers and their families can connect through a series of unique patches created just for them. OPP empowers kids to find their inner warriors, as they endure life changing milestone events, by providing a bit of sunshine throughout their cancer journey.",
		more: {
			type: "pdf",
			url: "https://nchcf.org/k-assets/gc-voting/oliver-patch-project.pdf",
		},
	},
];

const Vote = ({ handleStatus }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		programVotes: Array(programs.length).fill(0),
		totalVotes: 0,
	});
	const [msg, setMsg] = useState({});
	const [modal, setModal] = useState({
		open: false,
		type: "",
		id: "",
	});

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		if (name === "program") {
			const split = value.split("|");
			const index = parseInt(split[0], 10);
			const votes = parseInt(split[1], 10);

			const newArray = names.programVotes;
			newArray[index] = votes;
			const sum = newArray.reduce((partialSum, a) => partialSum + a, 0);
			setNames((names) => ({
				...names,
				programVotes: newArray,
				totalVotes: sum,
			}));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=gcVoting`);
		let formData = new FormData(formElement.current);
		formData.append("programs", JSON.stringify(programs));
		formData.append("programVotes", JSON.stringify(names.programVotes));
		formData.append("totalVotes", JSON.stringify(names.totalVotes));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const obj = {
					...json,
				};
				handleStatus(json.id, 1, obj);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleCloseModal = () => {
		setModal({
			open: false,
			type: "",
			id: "",
		});
	};

	return (
		<>
			{modal.open && (
				<Modal
					type={modal.type}
					id={modal.id}
					handleCloseModal={handleCloseModal}
				/>
			)}

			<h2 style={{ color: "var(--blue)", fontSize: "1.5rem" }}>
				2023 Golden Cub Ambassadors Club Voting
			</h2>

			<p style={{ marginTop: "0" }}>
				Congratulations on another impactful year! We are proud to announce we
				have raised $254,000.
			</p>

			<p>
				How it works: Each Member will receive 20 votes to cast across any of
				the 8 programs. Any combination of exactly 20 votes must be selected
				before the form can be submitted.
			</p>

			<p>
				Grants will be determined based on a percentage of the total number of
				votes.
			</p>

			<p>Last day to submit your vote is Friday, December 8.</p>

			<form
				ref={formElement}
				method="post"
				action="/"
				onSubmit={(e) => handleSubmit(e)}
			>
				<h3 style={{ color: "var(--blue)", marginTop: "25px" }}>Member Name</h3>

				<div className="two">
					<div>
						<InputUncontrolled
							label="First Name"
							req={true}
							name="fname"
							update={handleUpdate}
							errors={errors}
							autocomplete="given-name"
						/>
					</div>
					<div>
						<InputUncontrolled
							label="Last Name"
							req={true}
							name="lname"
							update={handleUpdate}
							errors={errors}
							autocomplete="family-name"
						/>
					</div>
				</div>

				<h3 style={{ marginBottom: "25px", color: "var(--blue)" }}>
					Cast Your Votes
				</h3>

				<table>
					<thead>
						<tr>
							<th className="left">2023 Proposed Funding Opportunities</th>
							<th style={{ width: "15%", minWidth: "125px" }}>Votes</th>
						</tr>
					</thead>
					<tbody>
						{programs.map((item, index) => {
							return (
								<tr key={index}>
									<td className="left">
										<h3 style={{ textTransform: "none", marginBottom: "5px" }}>
											{item.name}
										</h3>
										<p
											style={{
												marginTop: "0",
												fontStyle: "italic",
												fontSize: "0.95rem",
												lineHeight: "20px",
											}}
										>
											{item.description}
										</p>

										{item.more && item.more.type === "pdf" ? (
											<p>
												<a
													itemProp="url"
													href={item.more.url}
													data-hover-background-color="#cf2861"
													data-hover-border-color="#cf2861"
													data-hover-color="#ffffff"
													className="qbutton  small center default"
													style={{
														color: "rgb(255, 255, 255)",
														borderColor: "rgb(182, 32, 83)",
														fontWeight: "400",
														fontSize: "14px",
														borderRadius: "32.5px",
														backgroundColor: "rgb(182, 32, 83)",
													}}
													target="_blank"
													rel="noreferrer"
												>
													Learn More
												</a>
											</p>
										) : (
											<button
												type="button"
												onClick={() =>
													setModal({
														open: true,
														type: item.more.type,
														id: item.more.id,
													})
												}
												style={{
													margin: "0",
													color: "rgb(255, 255, 255)",
													border: "none",
													borderColor: "rgb(182, 32, 83)",
													fontWeight: "400",
													fontSize: "14px",
													borderRadius: "32.5px",
													background: "rgb(182, 32, 83)",
													appearance: "none",
													cursor: "pointer",
													height: "30px",
													lineHeight: "30px",
													padding: "0 17px",
													display: "inline-block",
													textTransform: "uppercase",
													letterSpacing: "1px",
													textAlign: "center",
												}}
											>
												Learn More
											</button>
										)}
									</td>
									<td>
										<Select
											label=""
											req={false}
											name="program"
											value=""
											data={[{ value: `${index}|0`, name: "--" }].concat(
												Array.from(Array(20).fill(), (_, i) => i + 1).map(
													(num) => {
														return {
															value: `${index}|${num}`,
															name: num,
														};
													}
												)
											)}
											update={handleUpdate}
											errors={errors}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							<th className="right">
								<p
									style={{
										fontSize: "1.35rem",
										marginTop: "0",
										marginBottom: "0",
									}}
								>
									Vote Total
								</p>
							</th>
							<th>
								<p
									style={{
										fontSize: "1.35rem",
										marginTop: "0",
										marginBottom: "0",
										color:
											names.totalVotes === 20
												? "var(--success)"
												: "var(--error)",
									}}
								>
									{names.totalVotes}
								</p>
							</th>
						</tr>
					</tfoot>
				</table>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={msg.type === "working" ? "hidden" : "submit-container"}
					>
						<Submit name="Submit Votes" icon={faChevronCircleRight} />
					</div>
				</div>

				<p>
					<small>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a
							href="/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
						.
					</small>
				</p>
			</form>
		</>
	);
};

export default Vote;
