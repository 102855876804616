import React from "react";
import "./styles.css";

function getLabel(str) {
  let label = str;

  if (str === "member") {
    label = "Golden Cub Ambassador Member";
  } else if (str === "nonmember") {
    label = "Non-Member";
  }

  return label;
}

const Toggle = ({ name, data, selected, update }) => {
  return (
    <div className="toggle">
      <button
        type="button"
        className={selected === data.one ? "selected" : ""}
        onClick={() => update(name, data.one)}
      >
        <span>
          <span>{getLabel(data.one)}</span>
        </span>
      </button>
      <button
        type="button"
        className={selected === data.two ? "selected" : ""}
        onClick={() => update(name, data.two)}
      >
        <span>
          <span>{getLabel(data.two)}</span>
        </span>
      </button>
    </div>
  );
};

export default Toggle;
