import { useState, useEffect } from "react";
import Toggle from "../../components/Toggle";
import DonateButton from "../../components/DonateButton";
import Input from "../../components/Input";
import InputUncontrolled from "../../components/InputUncontrolled";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import Radios from "../../components/Radios";
import Textarea from "../../components/Textarea";

const GenD = ({ total, total2, update, errorsFromDonate }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({
    frequency: "One-time",
  });

  useEffect(() => {
    setErrors(errorsFromDonate);
  }, [errorsFromDonate]);

  const amounts = [
    { value: 2500, name: "$25" },
    { value: 5000, name: "$50" },
    { value: 7500, name: "$75" },
    { value: 10000, name: "$100" },
    { value: "other", name: "Other" },
  ];

  const handleUpdate = (name, value) => {
    if (name === "dedicate") {
      setNames((names) => ({ ...names, dedicate: !names.dedicate }));
      update(name, !names.dedicate);
    } else if (name === "toIntl") {
      setNames((names) => ({ ...names, toIntl: !names.toIntl }));
      update(name, !names.toIntl);
    } else if (name === "amount" && !isNaN(value)) {
      const newAmt = 0 + value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      total(newAmt);
      //update(name, value ? value : 0);
    } else if (name === "addlAmt" && !isNaN(value)) {
      const newAmt = 0 + value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      total2(newAmt);
      //update(name, value ? value : 0);
    } else if (name === "addlAmt" && isNaN(value)) {
      const newAmt = 0;
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      total2(newAmt);
    } else if (name === "other") {
      if (!isNaN(value.replace("$", ""))) {
        const otherAmt = value.replace("$", "");
        const newAmt = 0 + Number(otherAmt);
        setNames((names) => ({
          ...names,
          [name]: value ? Number(otherAmt) : 0,
        }));
        total(newAmt * 100);
      }
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      update(name, value ? value : "");
    }
  };

  return (
    <div id="GenD">
      <h3>Frequency of Donation?</h3>
      <div>
        <Toggle
          name="frequency"
          data={{
            one: "Monthly",
            two: "One-time",
          }}
          selected={names.frequency}
          update={handleUpdate}
        />
      </div>

      <h3>Donation Amount</h3>
      <div className="buttons">
        {amounts.map((item, index) => {
          return (
            <DonateButton
              key={index}
              name="amount"
              data={item}
              click={handleUpdate}
              selected={names.amount === item.value ? true : false}
            />
          );
        })}
      </div>

      <div className={names.amount === "other" ? "one other" : "hidden"}>
        <InputUncontrolled
          label="Other Donation Amount"
          req={true}
          name="other"
          update={handleUpdate}
          errors={errors}
        />
      </div>

      <div className="checkbox">
        <Checkbox
          name="dedicate"
          update={handleUpdate}
          note="Honor someone special or memorialize someone who has passed with your gift. You can opt to send a notification by email."
          data={[
            {
              value: false,
              label: "Dedicate My Donation",
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.dedicate,
            };
          })}
        />
      </div>

      <div className={names.dedicate ? "three" : "hidden"}>
        <div>
          <Select
            label="My gift is:"
            req={false}
            name="memory"
            value=""
            data={[
              { value: "in memory of", name: "in memory of" },
              { value: "in honor of", name: "in honor of" },
            ]}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="memoryFname"
            value={names.memoryFname}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="memoryLname"
            value={names.memoryLname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <h3 className={names.dedicate ? "margin-top" : "hidden"}>
        This gift is from:
      </h3>

      <div className={names.dedicate ? "two" : "hidden"}>
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="fromFname"
            value={names.fromFname}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="fromLname"
            value={names.fromLname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <h3 className={names.dedicate ? "margin-top" : "hidden"}>
        Send gift notification to:
      </h3>

      <div className={names.dedicate ? "two" : "hidden"}>
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="toFname"
            value={names.toFname}
            update={handleUpdate}
            errors={errors}
          />
        </div>

        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="toLname"
            value={names.toLname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={names.dedicate ? "one top-margin bottom-margin" : "hidden"}
      >
        <div>
          <Input
            type="text"
            label="What is the relationship of the person you are honoring to the person you want notified?"
            req={false}
            name="relationship"
            value={names.relationship}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={
          (names.dedicate && !names.notify) || (names.dedicate && names.notify)
            ? "textarea"
            : "hidden"
        }
      >
        <div>
          <Textarea
            label="Optional Personal Message"
            req={false}
            name="message"
            value={names.message}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className={names.dedicate ? "radios" : "hidden"}>
        <Radios
          name="notify"
          hdg="How would you like to send your notification?"
          req={true}
          update={handleUpdate}
          data={[
            { value: "email", label: "By email" },
            { value: "mail", label: "By mail" },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.notify === item.value ? true : false,
            };
          })}
        />
      </div>

      <div
        className={
          names.dedicate && names.notify === "email" ? "one" : "hidden"
        }
      >
        <div style={{ marginBottom: "15px" }}>
          <Input
            type="text"
            label="Recipient’s Email"
            req={true}
            name="toEmail"
            value={names.toEmail}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={names.dedicate && names.notify === "mail" ? "two" : "hidden"}
      >
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="toAddress"
            value={names.toAddress}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="toAddress2"
            value={names.toAddress2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={
          names.dedicate && names.notify === "mail" ? "three" : "hidden"
        }
      >
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="toCity"
            value={names.toCity}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="US State"
            req={true}
            name="toSt"
            value={names.toSt}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip / Postal Code"
            req={true}
            name="toZip"
            value={names.toZip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={
          names.dedicate && names.notify === "mail" ? "checkbox" : "hidden"
        }
      >
        <Checkbox
          name="toIntl"
          update={handleUpdate}
          note=""
          data={[
            {
              value: false,
              label: "My billing address is outside the US.",
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.toIntl,
            };
          })}
        />
      </div>

      <div
        className={
          names.dedicate && names.notify === "mail" && names.toIntl
            ? "one"
            : "hidden"
        }
      >
        <div>
          <Input
            type="text"
            label="Country"
            req={true}
            name="toCountry"
            value={names.toCountry}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div className="textarea">
        <div>
          <Textarea
            label="Comments"
            req={false}
            name="comment"
            value={names.comment}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default GenD;
