import { useState, useEffect } from "react";
import DonateButton from "../../components/DonateButton";
import Input from "../../components/Input";
import InputUncontrolled from "../../components/InputUncontrolled";

const GcAc = ({ total, total2, update, errorsFromDonate }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});

	useEffect(() => {
		setErrors(errorsFromDonate);
	}, [errorsFromDonate]);

	useEffect(() => {
		handleUpdate("amount", 100000);

		// eslint-disable-next-line
	}, []);

	const handleUpdate = (name, value) => {
		if (name === "amount" && !isNaN(value)) {
			const newAmt = 0 + value;
			setNames((names) => ({ ...names, [name]: value ? value : 0 }));
			total(newAmt);
			update(
				"frequency",
				value === 100000 || value === 198600 ? "Yearly" : "One-time"
			);
		} else if (name === "addlAmt" && !isNaN(value)) {
			const newAmt = 0 + value;
			setNames((names) => ({ ...names, [name]: value ? value : 0 }));
			total2(newAmt);
			//update(name, value ? value : 0);
		} else if (name === "addlAmt" && isNaN(value)) {
			const newAmt = 0;
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			total2(newAmt);
		} else if (name === "other") {
			if (!isNaN(value.replace("$", ""))) {
				const otherAmt = value.replace("$", "");
				const newAmt = 0 + Number(otherAmt);
				setNames((names) => ({
					...names,
					[name]: value ? Number(otherAmt) : 0,
				}));
				total2(newAmt * 100);
			}
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			update(name, value ? value : "");
		}
	};

	return (
		<div id="GcAc">
			<h3>Select Membership / Enter Donation</h3>

			<div
				className="buttons"
				style={{ marginTop: "8.5px", gridTemplateColumns: "1fr 1fr" }}
			>
				<DonateButton
					name="amount"
					data={{
						value: 100000,
						name: "Individual $1000 annually/auto-renew",
					}}
					click={handleUpdate}
					selected={names.amount === 100000 ? true : false}
					spanFull={true}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 198600,
						name: "Couples $1986 annually/auto-renew",
					}}
					click={handleUpdate}
					selected={names.amount === 198600 ? true : false}
					spanFull={true}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 10000000,
						name: "Premier Cub Sponsor - $100,000",
					}}
					click={handleUpdate}
					selected={names.amount === 10000000 ? true : false}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 5000000,
						name: "Platinum Cub Sponsor - $50,000",
					}}
					click={handleUpdate}
					selected={names.amount === 5000000 ? true : false}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 2500000,
						name: "Silver Cub Sponsor - $25,000",
					}}
					click={handleUpdate}
					selected={names.amount === 2500000 ? true : false}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 1000000,
						name: "Bronze Cub Sponsor - $10,000",
					}}
					click={handleUpdate}
					selected={names.amount === 1000000 ? true : false}
				/>
				<DonateButton
					name="amount"
					data={{
						value: 0,
						name: "Support initiative with a one-time donation",
					}}
					click={handleUpdate}
					selected={names.amount === 0 ? true : false}
					spanFull={true}
				/>
			</div>

			<div className="two" style={{ marginTop: "25px" }}>
				<div>
					<Input
						type="text"
						label="First Name"
						req={true}
						name="fname"
						value={names.fname}
						update={handleUpdate}
						errors={errors}
						autocomplete="given-name"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Last Name"
						req={true}
						name="lname"
						value={names.lname}
						update={handleUpdate}
						errors={errors}
						autocomplete="family-name"
					/>
				</div>
			</div>

			{names.amount === 198600 && (
				<div className="two">
					<div>
						<Input
							type="text"
							label="2nd First Name (for couples)"
							req={true}
							name="gcFname2"
							value={names.gcFname2}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="2nd Last Name (for couples)"
							req={true}
							name="gcLname2"
							value={names.gcLname2}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>
				</div>
			)}

			<div className="two">
				<div>
					<Input
						type="text"
						label="Email Address"
						req={true}
						name="email"
						value={names.email}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Phone"
						req={true}
						name="phone"
						value={names.phone}
						update={handleUpdate}
						errors={errors}
						autocomplete="tel"
					/>
				</div>
			</div>

			{names.amount === 198600 && (
				<div className="two">
					<div>
						<Input
							type="text"
							label="2nd Email Address (for couples)"
							req={true}
							name="gcEmail2"
							value={names.gcEmail2}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>
					<div>
						<Input
							type="text"
							label="2nd Phone (for couples)"
							req={true}
							name="gcPhone2"
							value={names.gcPhone2}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>
				</div>
			)}

			<div className="two">
				<div>
					<Input
						type="text"
						label="Address"
						req={true}
						name="address"
						value={names.address}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Address 2"
						req={false}
						name="address2"
						value={names.address2}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
			</div>

			<div className="three">
				<div>
					<Input
						type="text"
						label="City"
						req={true}
						name="city"
						value={names.city}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
				<div className="small">
					<Input
						type="text"
						label="US State"
						req={true}
						name="st"
						value={names.st}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
				<div className="small">
					<Input
						type="text"
						label="Zip / Postal Code"
						req={true}
						name="zip"
						value={names.zip}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="How do you wish to be listed?"
						req={true}
						name="gcListed"
						value={names.gcListed}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				{names.amount === 100000 || names.amount === 198600 ? (
					<div>
						<Input
							type="text"
							label="How did you hear about the GCAC?"
							req={false}
							name="gcHeard"
							value={names.gcHeard}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
				) : (
					<div className="empty" />
				)}
			</div>

			{names.amount === 0 && (
				<div style={{ marginTop: "15px" }}>
					<div>
						<Input
							type="text"
							label="Comments"
							req={false}
							name="gcComments"
							value={names.gcComments}
							update={handleUpdate}
							errors={errors}
						/>
					</div>
				</div>
			)}

			<p className="line" />

			<h3>
				{names.amount === 0
					? "One-Time Donation Amount"
					: "Optional One-Time Donation Amount"}
			</h3>

			{/* removed per Rachel 6/7/22
        <div className="buttons">
        {[
          { value: 0, name: "None" },
          { value: 2500, name: "$25" },
          { value: 5000, name: "$50" },
          { value: 7500, name: "$75" },
          { value: 10000, name: "$100" },
          { value: "other", name: "Other" },
        ].map((item, index) => {
          return (
            <DonateButton
              key={index}
              name="addlAmt"
              data={item}
              click={handleUpdate}
              selected={names.addlAmt === item.value ? true : false}
            />
          );
        })}
      </div>*/}

			<div className="one other">
				<InputUncontrolled
					label="Donation Amount"
					req={names.amount === 0 ? true : false}
					name="other"
					update={handleUpdate}
					errors={errors}
				/>
			</div>
		</div>
	);
};

export default GcAc;
