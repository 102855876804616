import Alert from "../components/Alert";

const Thanks = ({ id, data }) => {
	return (
		<>
			<h2 style={{ color: "var(--blue)", fontSize: "1.5rem" }}>
				2023 Golden Cub Ambassadors Club Voting
			</h2>

			<Alert
				data={{ type: "success", text: `Thank you for voting, ${data.fname}` }}
			/>

			<h3 style={{ marginBottom: "25px" }}>Your Votes</h3>

			<table>
				<thead>
					<tr>
						<th className="left">Program Name</th>
						<th style={{ width: "15%", minWidth: "125px" }}>Votes</th>
					</tr>
				</thead>
				<tbody>
					{data.programs.map((item, index) => {
						return (
							data.programVotes[index] > 0 && (
								<tr key={index}>
									<td className="left">
										<h3 style={{ textTransform: "none" }}>{item.name}</h3>
									</td>
									<td>
										<h3>{data.programVotes[index]}</h3>
									</td>
								</tr>
							)
						);
					})}
				</tbody>
			</table>
		</>
	);
};

export default Thanks;
