import { useState, useEffect } from "react";

import Toggle from "../../components/Toggle";
//import DonateButton from "../../components/DonateButton";
import Input from "../../components/Input";
//import InputUncontrolled from "../../components/InputUncontrolled";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
//import Msg from "../../components/Msg";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

/*
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
*/

const GcGc = ({ total, total2, update, errorsFromDonate, formElement }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		interested: false,
		dinnerOnly: false,
		addlDinnerOnly: false,
	});
	//const [addlParticipants, setAddlParticipants] = useState([]);
	//const [msg, setMsg] = useState({});

	useEffect(() => {
		setErrors(errorsFromDonate);
	}, [errorsFromDonate]);

	useEffect(() => {
		handleUpdate("gcgcType", "member");

		// eslint-disable-next-line
	}, []);

	const handleUpdate = (name, value) => {
		if (
			name === "interested" ||
			name === "dinnerOnly" ||
			name === "addlDinnerOnly"
		) {
			setNames((names) => ({ ...names, [name]: !names[name] }));
			update(name, !names[name]);
		} else if (name === "gcgcType") {
			const amt = value === "member" ? 0 : 50000;
			const addlMemberAmt =
				names.addlMember && names.addlMember === "nonmember" ? 50000 : 0;
			const totalAmt = amt + addlMemberAmt;
			setNames((names) => ({ ...names, [name]: value }));
			update(name, value ? value : "");
			total(totalAmt);
		} else if (name === "sponsorship") {
			if (value === "") {
				setNames((names) => ({ ...names, gcgcSponsorship: "" }));
				update("gcgcSponsorship", "");
				total2(0);
			} else {
				const split = value.split("|");
				setNames((names) => ({ ...names, gcgcSponsorship: split[0] }));
				update("gcgcSponsorship", split[0]);
				total2(Number(split[1]));
			}
		} else if (name === "addlMember") {
			const amt = names.gcgcType === "member" ? 0 : 50000;
			const addlMemberAmt = value === "nonmember" ? 50000 : 0;
			const totalAmt = amt + addlMemberAmt;
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			update(name, value ? value : "");
			total(totalAmt);
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			update(name, value ? value : "");
		}
	};

	/*
  const handleAdd = () => {
    setMsg({
      type: "working",
      text: "",
    });

    if (
      names.addlMember &&
      names.addlFname &&
      names.addlLname &&
      names.addlEmail &&
      names.addlPhone
    ) {
      let newArray = addlParticipants;
      newArray.push({
        member: names.addlMember,
        fname: names.addlFname,
        lname: names.addlLname,
        email: names.addlEmail,
        phone: names.addlPhone,
      });
      setAddlParticipants(newArray);
      update("gcgcAddlParticipants", newArray);

      setNames((names) => ({
        ...names,
        addlMember: "",
        addlFname: "",
        addlLname: "",
        addlEmail: "",
        addlPhone: "",
      }));
      formElement.current.addlMember.selectedIndex = 0;
      formElement.current.addlFname.value = "";
      formElement.current.addlLname.value = "";
      formElement.current.addlEmail.value = "";
      formElement.current.addlPhone.value = "";

      setMsg({});

      let totalAmt = names.gcgcType === "member" ? 0 : 25000;
      newArray.forEach((obj) => {
        const amt = obj.member === "member" ? 0 : 25000;
        totalAmt = totalAmt + amt;
      });
      total(totalAmt);
    } else {
      setMsg({
        type: "error",
        text: "Please make en entry in all participant fields.",
      });
    }
  };

  const handleRemove = (index) => {
    let newArray = addlParticipants;
    newArray.splice(index, 1);

    setAddlParticipants(newArray);
    update("gcgcAddlParticipants", newArray);

    let totalAmt = names.gcgcType === "member" ? 0 : 25000;
    newArray.forEach((obj) => {
      const amt = obj.member === "member" ? 0 : 25000;
      totalAmt = totalAmt + amt;
    });
    total(totalAmt);
  };
  */

	return (
		<div id="GcGc">
			<h3 style={{ color: "var(--blue)" }}>Select Registration Type</h3>

			<div>
				<Toggle
					name="gcgcType"
					data={{
						one: "member",
						two: "nonmember",
					}}
					selected={names.gcgcType}
					update={handleUpdate}
				/>
			</div>

			{names.gcgcType === "member" ? (
				<p>
					There is no cost to attend the Golf Classic for current Golden Cub
					Ambassadors. Please enter your participant details below to register.
				</p>
			) : (
				<>
					<p>
						Non-Member registration costs $500.00 per person to attend the
						Golden Cub Golf Classic.
					</p>

					<div className="checkbox">
						<Checkbox
							name="interested"
							update={handleUpdate}
							note="Please check if you are interested in becoming a Golden Cub Ambassadors Club member. Your Nine & Dine registration fee will be credited towards the cost of membership."
							data={[
								{
									value: false,
									label: "Interested in Membership",
								},
							].map((item) => {
								return {
									value: item.value,
									label: item.label,
									checked: names.interested,
								};
							})}
						/>
					</div>
				</>
			)}

			<div className="two" style={{ marginTop: "25px" }}>
				<div>
					<Input
						type="text"
						label="First Name"
						req={true}
						name="fname"
						value={names.fname}
						update={handleUpdate}
						errors={errors}
						autocomplete="given-name"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Last Name"
						req={true}
						name="lname"
						value={names.lname}
						update={handleUpdate}
						errors={errors}
						autocomplete="family-name"
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Email Address"
						req={true}
						name="email"
						value={names.email}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Cell Phone"
						req={true}
						name="phone"
						value={names.phone}
						update={handleUpdate}
						errors={errors}
						autocomplete="tel"
					/>
				</div>
			</div>

			<div className="checkbox" style={{ marginTop: "15px" }}>
				<Checkbox
					name="dinnerOnly"
					update={handleUpdate}
					note=""
					data={[
						{
							value: false,
							label: "I will be attending the dinner only (not playing golf)",
						},
					].map((item) => {
						return {
							value: item.value,
							label: item.label,
							checked: names.dinnerOnly,
						};
					})}
				/>
			</div>

			<p className="line" />

			<h3 style={{ marginBottom: "0", color: "var(--blue)" }}>
				Additional Participant
			</h3>

			<p>
				If you would like to register an additional participant, please enter
				their information below.
			</p>

			<div className="two">
				<div>
					<Select
						label="Select Member Status"
						req={false}
						name="addlMember"
						value=""
						data={[
							{ value: "", name: "--" },

							{
								value: "member",
								name: "Golden Cub Ambassador Member",
							},
							{
								value: "nonmember",
								name: "Non-Member",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div className="empty" />
			</div>

			<div className="two">
				<div>
					<Input
						label="First Name"
						req={false}
						name="addlFname"
						value={names.addlFname}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
				<div>
					<Input
						label="Last Name"
						req={false}
						name="addlLname"
						value={names.addlLname}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						label="Email Address"
						req={false}
						name="addlEmail"
						value={names.addlEmail}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
				<div>
					<Input
						label="Cell Phone"
						req={false}
						name="addlPhone"
						value={names.addlPhone}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
			</div>

			<div className="checkbox" style={{ marginTop: "15px" }}>
				<Checkbox
					name="addlDinnerOnly"
					update={handleUpdate}
					note=""
					data={[
						{
							value: false,
							label:
								"Additional participant will be attending the dinner only (not playing golf)",
						},
					].map((item) => {
						return {
							value: item.value,
							label: item.label,
							checked: names.addlDinnerOnly,
						};
					})}
				/>
			</div>

			{/*
      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div
          className={
            msg.type === "working" ? "hidden" : "add-participant-button"
          }
        >
          <button type="button" className="button" onClick={() => handleAdd()}>
            <span>Add a Participant</span>
          </button>
        </div>
      </div>


      {addlParticipants.length > 0 && (
        <div style={{ padding: "0 25px" }}>
          <table className="order">
            <thead>
              <tr>
                <th className="left">Additional Participant</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {addlParticipants.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div>
                        <span>{`${item.fname} ${
                          item.lname
                        } (${capitalizeFirstLetter(item.member)})`}</span>
                        <small>{`${item.email} / ${item.phone}`}</small>
                      </div>
                    </td>
                    <td className="center">
                      <button
                        type="button"
                        className="trash"
                        onClick={() => handleRemove(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      */}

			<p className="line" style={{ paddingTop: "5px" }} />

			<h3 style={{ marginBottom: "0", color: "var(--blue)" }}>
				Add Sponsorship
			</h3>

			<p>
				If you would like to participant in one of our Sponsorship
				opportunities, please select from the available levels below.{" "}
				<em>*All sponsors will be featured on our web site</em>.
			</p>

			<div className="two">
				<div>
					<Select
						label="Select Sponsorship Level"
						req={false}
						name="sponsorship"
						value=""
						data={[
							{ value: "", name: "--" },
							{
								value: "premier|10000000",
								name: "Premier Cub Sponsor ($100,000)",
							},
							{
								value: "platinum|5000000",
								name: "Platinum Cub Sponsor ($50,000)",
							},
							{
								value: "silver|2500000",
								name: "Silver Cub Sponsor ($25,000)",
							},
							{
								value: "bronze|1000000",
								name: "Bronze Cub Sponsor ($10,000)",
							},
							{
								value: "tee|50000",
								name: "Tee Sponsor ($500)",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				{names.gcgcSponsorship ? (
					<div>
						<Input
							type="text"
							label="How do you wish to be listed?"
							req={true}
							name="gcgcListed"
							value={names.gcgcListed}
							update={handleUpdate}
							errors={errors}
							autocomplete="off"
						/>
					</div>
				) : (
					<div className="empty" />
				)}
			</div>

			{names.gcgcSponsorship === "season" && (
				<ul>
					<li>
						Presenting sponsor of the Golden Cub Golf Classic (October 2022)
					</li>
					<li>Eight (8) playing spots in the Golden Cub Golf Classic</li>
					<li>
						Two (2) annual memberships for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Sponsor of the December Membership Meeting</li>
					<li>Premier logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "gold" && (
				<ul>
					<li>Four (4) playing spots in the Golden Cub Golf Classic</li>
					<li>
						Two (2) annual memberships for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "silver" && (
				<ul>
					<li>Two (2) playing spots in the Golden Cub Golf Classic</li>
					<li>
						One (1) annual membership for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "bronze" && (
				<ul>
					<li>One (1) playing spot in the Golden Cub Golf Classic</li>
					<li>
						One (1) annual membership for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			<p className="line" style={{ paddingTop: "5px" }} />
		</div>
	);
};

export default GcGc;
