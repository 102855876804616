import { useState, useEffect } from "react";
import Input from "../../components/Input";
import Select from "../../components/Select";

const GcSp = ({ total, total2, update, errorsFromDonate, formElement }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});

	useEffect(() => {
		setErrors(errorsFromDonate);
	}, [errorsFromDonate]);

	const handleUpdate = (name, value) => {
		if (name === "sponsorship") {
			if (value === "") {
				setNames((names) => ({ ...names, gcgcSponsorship: "" }));
				update("gcgcSponsorship", "");
				total2(0);
			} else {
				const split = value.split("|");
				setNames((names) => ({ ...names, gcgcSponsorship: split[0] }));
				update("gcgcSponsorship", split[0]);
				total2(Number(split[1]));
			}
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			update(name, value ? value : "");
		}
	};

	return (
		<div id="GcSp">
			<h3 style={{ marginBottom: "0", color: "var(--blue)" }}>
				Select Sponsorship
			</h3>

			<p>
				Please select from the available levels below to participant in one of
				our Sponsorship opportunities.{" "}
				<em>*All sponsors will be featured on our web site</em>.
			</p>

			<div className="two">
				<div>
					<Select
						label="Select Sponsorship Level"
						req={true}
						name="sponsorship"
						value=""
						data={[
							{ value: "", name: "--" },
							{
								value: "premier|10000000",
								name: "Premier Cub Sponsor ($100,000)",
							},
							{
								value: "platinum|5000000",
								name: "Platinum Cub Sponsor ($50,000)",
							},
							{
								value: "silver|2500000",
								name: "Silver Cub Sponsor ($25,000)",
							},
							{
								value: "bronze|1000000",
								name: "Bronze Cub Sponsor ($10,000)",
							},
							{
								value: "tee|50000",
								name: "Tee Sponsor ($500)",
							},
						]}
						update={handleUpdate}
						errors={errors}
					/>
				</div>

				<div>
					<Input
						type="text"
						label="How do you wish to be listed?"
						req={true}
						name="gcgcListed"
						value={names.gcgcListed}
						update={handleUpdate}
						errors={errors}
						autocomplete="off"
					/>
				</div>
			</div>

			{names.gcgcSponsorship === "season" && (
				<ul>
					<li>
						Presenting sponsor of the Golden Cub Golf Classic (October 2022)
					</li>
					<li>Eight (8) playing spots in the Golden Cub Golf Classic</li>
					<li>
						Two (2) annual memberships for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Sponsor of the December Membership Meeting</li>
					<li>Premier logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "gold" && (
				<ul>
					<li>Four (4) playing spots in the Golden Cub Golf Classic</li>
					<li>
						Two (2) annual memberships for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "silver" && (
				<ul>
					<li>Two (2) playing spots in the Golden Cub Golf Classic</li>
					<li>
						One (1) annual membership for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			{names.gcgcSponsorship === "bronze" && (
				<ul>
					<li>One (1) playing spot in the Golden Cub Golf Classic</li>
					<li>
						One (1) annual membership for the Golden Cub Ambassadors Club (for
						that calendar year)
					</li>
					<li>Logo placement*</li>
				</ul>
			)}

			<p className="line" style={{ paddingTop: "5px" }} />

			<div className="two" style={{ marginTop: "25px" }}>
				<div>
					<Input
						type="text"
						label="First Name"
						req={true}
						name="fname"
						value={names.fname}
						update={handleUpdate}
						errors={errors}
						autocomplete="given-name"
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Last Name"
						req={true}
						name="lname"
						value={names.lname}
						update={handleUpdate}
						errors={errors}
						autocomplete="family-name"
					/>
				</div>
			</div>

			<div className="two">
				<div>
					<Input
						type="text"
						label="Email Address"
						req={true}
						name="email"
						value={names.email}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
				<div>
					<Input
						type="text"
						label="Cell Phone"
						req={true}
						name="phone"
						value={names.phone}
						update={handleUpdate}
						errors={errors}
						autocomplete="tel"
					/>
				</div>
			</div>

			<p className="line" style={{ paddingTop: "5px" }} />
		</div>
	);
};

export default GcSp;
