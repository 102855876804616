import { useState, useEffect } from "react";
import DonateButton from "../../components/DonateButton";
import Input from "../../components/Input";

const RiH = ({ total, total2, update, errorsFromDonate }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({
    boxes: 0,
  });

  useEffect(() => {
    setErrors(errorsFromDonate);
  }, [errorsFromDonate]);

  const amounts = [
    { value: 3000, name: "$30" },
    { value: 6000, name: "$60" },
    { value: 9000, name: "$90" },
    { value: 15000, name: "$150" },
    { value: 24000, name: "$240" },
    { value: 30000, name: "$300" },
    { value: 45000, name: "$450" },
    { value: 60000, name: "$600" },
    { value: 90000, name: "$900" },
    { value: "other", name: "Other" },
  ];

  const handleUpdate = (name, value) => {
    if (name === "amount" && value === "other") {
      const boxes = 0;
      setNames((names) => ({ ...names, amount: "other", other: "", boxes }));
      total(0);
      update("boxes", boxes);
    } else if (name === "amount") {
      const boxes = value / 3000;
      setNames((names) => ({
        ...names,
        [name]: value ? value : "",
        other: "",
        boxes,
      }));
      total(value);
      update("boxes", boxes);
    } else if (name === "other") {
      if (!isNaN(value.replace("$", ""))) {
        const otherAmt = value.replace("$", "");
        const newAmt = 0 + Number(otherAmt);
        const boxes = Math.floor((newAmt * 100) / 3000);
        setNames((names) => ({
          ...names,
          [name]: value ? newAmt : 0,
          boxes,
        }));
        total(newAmt * 100);
        update(name, value ? newAmt : 0);
        update("boxes", boxes);
      }
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      update(name, value ? value : "");
    }
  };

  return (
    <div id="RiH">
      <h3>Donation Amount</h3>
      <div className="buttons">
        {amounts.map((item, index) => {
          return (
            <DonateButton
              key={index}
              name="amount"
              data={item}
              click={handleUpdate}
              selected={names.amount === item.value ? true : false}
            />
          );
        })}
      </div>

      <div className={names.amount === "other" ? "one other" : "hidden"}>
        <Input
          type="text"
          label="Other Donation Amount"
          req={true}
          name="other"
          value={names.other}
          update={handleUpdate}
          errors={errors}
        />
      </div>

      <p>
        <strong>
          {`${
            names.boxes === 1 ? `${names.boxes} box` : `${names.boxes} boxes`
          } of books donated to a NICU baby at Nicklaus Children’s Hospital`}
        </strong>
      </p>
    </div>
  );
};

export default RiH;
