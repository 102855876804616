import { useState, useEffect } from "react";
import Radios from "../../components/Radios";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";

const GaB = ({ total, total2, update, errorsFromDonate }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});

  useEffect(() => {
    setErrors(errorsFromDonate);
  }, [errorsFromDonate]);

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
    update(name, value);
    total(25000);
  };

  return (
    <div>
      <div
        className={
          names.bears === "Send someone else my bear" ? "bottom-margin" : ""
        }
      >
        <Radios
          name="bears"
          hdg="Would you like to donate both bears to children healing at Nicklaus Children’s Hospital?"
          update={handleUpdate}
          data={[
            {
              value: "Donate both bears",
              label:
                "Yes, donate both of my bears to children healing at Nicklaus Children’s Hospital.",
            },
            {
              value: "Send me my bear",
              label:
                "Please send me my bear and donate one to a child healing at Nicklaus Children’s Hospital.",
            },
            {
              value: "Send someone else my bear",
              label:
                "Please send my bear to someone else and donate one to a child healing at Nicklaus Children’s Hospital.",
            },
          ].map((item) => {
            return {
              value: item.value,
              label: item.label,
              checked: names.bears === item.value ? true : false,
            };
          })}
        />
      </div>

      <h3
        className={names.bears === "Send someone else my bear" ? "" : "hidden"}
      >
        Where would like us to send your bear?
      </h3>

      <div
        className={
          names.bears === "Send someone else my bear" ? "two" : "hidden"
        }
      >
        <div>
          <Input
            type="text"
            label="First Name"
            req={true}
            name="gabFname"
            value={names.gabFname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Last Name"
            req={true}
            name="gabLname"
            value={names.gabLname}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <p
        className={
          names.bears === "Send someone else my bear" ? "line" : "hidden"
        }
      />

      <div
        className={
          names.bears === "Send someone else my bear" ? "two" : "hidden"
        }
      >
        <div>
          <Input
            type="text"
            label="Address"
            req={true}
            name="gabAddress"
            value={names.gabAddress}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div>
          <Input
            type="text"
            label="Address 2"
            req={false}
            name="gabAddress2"
            value={names.gabAddress2}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={
          names.bears === "Send someone else my bear"
            ? "three bottom-margin"
            : "hidden"
        }
      >
        <div>
          <Input
            type="text"
            label="City"
            req={true}
            name="gabCity"
            value={names.gabCity}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="State"
            req={true}
            name="gabSt"
            value={names.gabSt}
            update={handleUpdate}
            errors={errors}
          />
        </div>
        <div className="small">
          <Input
            type="text"
            label="Zip"
            req={true}
            name="gabZip"
            value={names.gabZip}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>

      <div
        className={
          names.bears === "Send someone else my bear" ? "textarea" : "hidden"
        }
      >
        <div>
          <Textarea
            label="Optional Personal Message"
            req={false}
            name="gabMessage"
            value={names.gabMessage}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default GaB;
