import React from "react";
import "./styles.css";

function DonateButton({
  name,
  data,
  selected,
  click,
  visibility = "",
  spanFull = false,
}) {
  let classes = `button-one`;
  if (selected) {
    classes += ` selected`;
  }
  if (spanFull) {
    classes += ` span-full`;
  }

  return (
    <button
      type="button"
      className={classes}
      onClick={() => click(name, data.value)}
      style={{
        visibility: visibility,
      }}
    >
      <span>
        <span>{data.name}</span>
      </span>
    </button>
  );
}

export default DonateButton;
