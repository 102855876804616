import React from "react";
import "./styles.css";

function isFieldError(array, val) {
  return array.includes(val);
}

const Input = ({
  type,
  label,
  note = "",
  req,
  name,
  value,
  update,
  errors,
  autocomplete = name,
}) => {
  return (
    <div className="input" name={name}>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <input
        type={type === "password" ? "password" : "text"}
        name={type === "text-secure" ? null : name}
        id={name}
        value={value || ""}
        onChange={(e) => update(name, e.target.value)}
        autoComplete={autocomplete}
        className={isFieldError(errors, name) ? "error-border" : ""}
      />
      <small className={note ? "" : "hidden"}>{note}</small>
    </div>
  );
};

export default Input;
