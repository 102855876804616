import { useState, useEffect } from "react";
import DonateButton from "../../components/DonateButton";
import InputUncontrolled from "../../components/InputUncontrolled";
import Textarea from "../../components/Textarea";

const Golf = ({ total, total2, update, errorsFromDonate }) => {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});

  useEffect(() => {
    setErrors(errorsFromDonate);
  }, [errorsFromDonate]);

  const handleUpdate = (name, value) => {
    if (name === "amount" && !isNaN(value)) {
      const newAmt = 0 + value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      total(newAmt);
      //update(name, value ? value : 0);
    } else if (name === "addlAmt" && !isNaN(value)) {
      const newAmt = 0 + value;
      setNames((names) => ({ ...names, [name]: value ? value : 0 }));
      total2(newAmt);
      //update(name, value ? value : 0);
    } else if (name === "addlAmt" && isNaN(value)) {
      const newAmt = 0;
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      total2(newAmt);
    } else if (name === "other") {
      if (!isNaN(value.replace("$", ""))) {
        const otherAmt = value.replace("$", "");
        const newAmt = 0 + Number(otherAmt);
        setNames((names) => ({
          ...names,
          [name]: value ? Number(otherAmt) : 0,
        }));
        total(newAmt * 100);
      }
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
      update(name, value ? value : "");
    }
  };

  return (
    <div id="Golf">
      <h3>Donation Amount</h3>
      <div className="buttons">
        {[
          { value: 25000, name: "$250" },
          { value: 50000, name: "$500" },
          { value: 100000, name: "$1,000" },
          { value: 500000, name: "$5,000" },
          { value: "other", name: "Other" },
        ].map((item, index) => {
          return (
            <DonateButton
              key={index}
              name="amount"
              data={item}
              click={handleUpdate}
              selected={names.amount === item.value ? true : false}
            />
          );
        })}
      </div>

      {/* removed 4/7/22 per Rachel

        <div
        className="buttons"
        style={{ marginTop: "8.5px", gridTemplateColumns: "1fr" }}
      >
        <DonateButton
          name="amount"
          data={{ value: 10000, name: "$100 The Hills Play Yellow Hat" }}
          click={handleUpdate}
          selected={names.amount === 10000 ? true : false}
        />
      </div>*/}

      <div
        className={names.amount === "other" ? "one other" : "hidden"}
        style={{ marginTop: "15px" }}
      >
        <InputUncontrolled
          label="Other Donation Amount"
          req={true}
          name="other"
          update={handleUpdate}
          errors={errors}
        />
      </div>

      <div className="textarea" style={{ marginTop: "15px" }}>
        <div>
          <Textarea
            label="Comments"
            req={false}
            name="comment"
            value={names.comment}
            update={handleUpdate}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default Golf;
