function OrderDetails({ order }) {
  return (
    <>
      <table className="order hide-on-mobile">
        <thead>
          <tr>
            <th>Date</th>
            <th className="left">Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => {
            return (
              <tr key={index}>
                <td className="center">
                  <span>{order.date}</span>
                </td>
                <td>
                  <div>
                    <span>{item.item}</span>
                    <small>{item.subitem}</small>
                  </div>
                </td>
                <td className="right">{item.price}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {order.totals.map((item, index) => {
            return (
              <tr key={index + 10}>
                <td colSpan="2" className="right">
                  {item.name}
                </td>
                <td className="right">{item.value}</td>
              </tr>
            );
          })}
        </tfoot>
      </table>
      <table className="order hide-on-desktop">
        <thead>
          <tr>
            <th className="left">
              Order Number {order.id} on {order.date}
            </th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div>
                    <span>{item.item}</span>
                    <small>{item.subitem}</small>
                  </div>
                </td>
                <td className="right">{item.price}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {order.totals.map((item, index) => {
            return (
              <tr key={index + 10}>
                <td className="right">{item.name}</td>
                <td className="right">{item.value}</td>
              </tr>
            );
          })}
        </tfoot>
      </table>
    </>
  );
}

export default OrderDetails;
