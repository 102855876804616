import { useState, useEffect, useRef, createRef } from "react";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_KEY } from "../../utils/helpers";
import Input from "../../components/Input/index";
import Msg from "../../components/Msg/index";
import Submit from "../../components/Submit/index";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

const inputStyle = {
	backgroundColor: "#fff",
	fontSize: "18px",
	fontWeight: "300",
	fontFamily: "inherit",
};

const inputStyleError = {
	iconColor: "var(--error)",
	color: "var(--error)",
};

const CreditCard = ({ data, total, success, msgFromPayment }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [ccErrors, setCcErrors] = useState({
		ccNumber: false,
		ccExpDate: false,
		ccCode: false,
	});
	const [ccMsg, setCcMsg] = useState({});

	const recaptchaRef = createRef();
	const formElement = useRef(null);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (msgFromPayment.type) {
			setMsg(msgFromPayment);
		}
	}, [msgFromPayment]);

	useEffect(() => {
		const array = Object.values(ccErrors);
		const found = array.find((el) => el === true);
		setCcMsg(
			found
				? {
						type: "error",
						text: "Please enter a valid credit card.",
				  }
				: {}
		);
	}, [ccErrors]);

	const handleUpdate = (name, value) => {
		if (name === "intl") {
			setNames((names) => ({ ...names, intl: !names.intl }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleChange = (e, name) => {
		setCcErrors((ccErrors) => ({
			...ccErrors,
			[name]: e.complete ? false : true,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		let recaptcha = names.recaptcha
			? names.recaptcha
			: await recaptchaRef.current.executeAsync();

		const url = new URL(`${API_URL}/?f=payment`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			if (key === "recaptcha") {
			} else if (key === "intl") {
				formData.append(key, value ? "1" : "0");
			} else {
				formData.append(key, value);
			}
		});
		formData.append("id", data.id);
		formData.append("payment", "cc");
		formData.append("recaptcha", recaptcha);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				handlePayment(data);
			} else {
				if (json.fields[0] === "recaptcha") {
					handleUpdate("recaptcha", "");
					recaptchaRef.current.reset();
				} else {
					handleUpdate("recaptcha", recaptcha);
				}
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handlePayment = async (obj) => {
		const result = await stripe.confirmCardPayment(obj.clientSecret, {
			payment_method: {
				card: elements.getElement(CardNumberElement),
				billing_details: {
					address: {
						city: null,
						country: null,
						line1: null,
						line2: null,
						postal_code: null,
						state: null,
					},
					email: null,
					name: `${names.cardholder}`,
				},
			},
		});

		if (result.error) {
			setMsg({
				type: "error",
				text: result.error.message,
			});
		} else {
			if (result.paymentIntent.status === "succeeded") {
				success({
					...obj,
					paymentIntentId: result.paymentIntent.id,
					status: result.paymentIntent.status,
					paymentMethod: result.paymentIntent.payment_method,
					amount: result.paymentIntent.amount,
				});
			}
		}
	};

	return (
		<form
			ref={formElement}
			method="post"
			action="/"
			onSubmit={(e) => handleSubmit(e)}
		>
			<div className="three">
				<div>
					<div className="input">
						<label htmlFor="ccNumber" style={{ paddingBottom: "7px" }}>
							Credit Card Number <span className="error">*</span>
						</label>
						<div
							className="stripe-element-wrapper"
							style={{
								borderColor: ccErrors.ccNumber ? "var(--error)" : "#ccc",
							}}
						>
							<CardNumberElement
								id="ccNumber"
								options={{
									style: {
										base: inputStyle,
										invalid: inputStyleError,
									},
									showIcon: true,
								}}
								onChange={(e) => handleChange(e, "ccNumber")}
								//onFocus={(e) => handleCheck(e, "ccNumber")}
							/>
						</div>
					</div>
				</div>

				<div className="small">
					<div className="input">
						<label htmlFor="ccExpDate" style={{ paddingBottom: "7px" }}>
							Exp. Date <span className="error">*</span>
						</label>
						<div
							className="stripe-element-wrapper"
							style={{
								borderColor: ccErrors.ccExpDate ? "var(--error)" : "#ccc",
							}}
						>
							<CardExpiryElement
								id="ccExpDate"
								options={{
									style: {
										base: inputStyle,
										invalid: inputStyleError,
									},
								}}
								onChange={(e) => handleChange(e, "ccExpDate")}
								//onFocus={(e) => handleCheck(e, "ccExpDate")}
							/>
						</div>
					</div>
				</div>

				<div className="small">
					<div className="input">
						<label htmlFor="ccCode" style={{ paddingBottom: "7px" }}>
							CVV/CID <span className="error">*</span>
						</label>
						<div
							className="stripe-element-wrapper"
							style={{
								borderColor: ccErrors.ccCode ? "var(--error)" : "#ccc",
							}}
						>
							<CardCvcElement
								id="ccCode"
								options={{
									style: {
										base: inputStyle,
										invalid: inputStyleError,
									},
									placeholder: "",
								}}
								onChange={(e) => handleChange(e, "ccCode")}
								//onFocus={(e) => handleCheck(e, "ccCode")}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="msg-submit">{ccMsg.type && <Msg data={ccMsg} />}</div>

			<p className="line" />

			<h3>Cardholder Name</h3>

			<div className="one">
				<div>
					<Input
						type="text"
						label="Name"
						req={true}
						name="cardholder"
						value={names.cardholder}
						update={handleUpdate}
						errors={errors}
						autocomplete="name"
					/>
				</div>
			</div>

			<div className="one">
				<ReCAPTCHA
					ref={recaptchaRef}
					size="invisible"
					sitekey={RECAPTCHA_KEY}
					badge="inline"
				/>
			</div>

			<div className="msg-submit">
				{msg.type && <Msg data={msg} />}

				<div className={msg.type === "working" ? "hidden" : "submit-container"}>
					<Submit name="Submit Payment" icon={faChevronCircleRight} />
				</div>
			</div>
		</form>
	);
};

export default CreditCard;
