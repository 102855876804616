import { useState, useEffect } from "react";
import { API_URL, scroll } from "../utils/helpers";
import PaymentMethod from "./PaymentMethod/index";
import CreditCard from "./CreditCard/index";
import OtherPay from "./OtherPay/index";
import OrderDetails from "./OrderDetails/index";
import Alert from "../components/Alert";
import NoticeText from "../components/NoticeText";

const Payment = ({ status, data }) => {
	const [names, setNames] = useState({
		payment: "cc",
	});
	const [msg, setMsg] = useState({});
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		scroll();
	}, []);

	useEffect(() => {
		const init = async (id) => {
			const url = new URL(`${API_URL}/`);
			const params = { f: "paymentInit", id, q: "1012" };
			url.search = new URLSearchParams(params);

			try {
				const response = await fetch(url, {
					method: "GET",
					cache: "no-store",
				});
				const json = await response.json();
				if (json && json.resp === 1) {
					setOrder({
						id: json.id,
						date: json.date,
						items: json.items,
						totals: json.totals,
						text: json.text,
						subText: json.subText,
					});
					setTotal(json.total);
					setAlert({});
				} else {
					setAlert({
						type: "error",
						text: json.text,
					});
				}
			} catch (error) {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			}
		};

		if (data.id) {
			init(data.id);
		}
	}, [data]);

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSuccess = async (obj) => {
		const url = new URL(`${API_URL}/?f=submit`);
		let formData = new FormData();
		formData.append("id", obj.id);
		formData.append("customerId", obj.customerId);
		formData.append("subscriptionId", obj.subscriptionId);
		formData.append(
			"stripeResponse",
			JSON.stringify({
				paymentIntentId: obj.paymentIntentId,
				status: obj.status,
				paymentMethod: obj.paymentMethod,
				amount: obj.amount,
			})
		);
		/* not using data returned from applePay or googlePay
      formData.append("name", obj.name ? obj.name : "");
      formData.append("email", obj.email ? obj.email : "");
      formData.append("phone", obj.phone ? obj.phone : "");
    */
		formData.append("payment", names.payment);
		formData.append("otherPay", names.otherPay || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				status(json.id, 1);
			} else {
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<NoticeText text={order.text} />
					<OrderDetails order={order} />
					<PaymentMethod update={handleUpdate} />
					{names.payment === "cc" ? (
						<CreditCard
							data={data}
							total={total}
							success={handleSuccess}
							msgFromPayment={msg}
						/>
					) : (
						<OtherPay
							data={data}
							total={total}
							success={handleSuccess}
							msgFromPayment={msg}
							update={handleUpdate}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default Payment;
