import React, { useState, useEffect, Fragment } from "react";
import { API_URL, scroll } from "../utils/helpers";
import Alert from "../components/Alert";

const Receipt = ({ id }) => {
	const [order, setOrder] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		scroll();
	}, []);

	useEffect(() => {
		init(id);
	}, [id]);

	const init = async (id) => {
		const url = new URL(`${API_URL}/`);
		const params = { f: "receiptInit", id };
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setOrder({
					id: json.id,
					donating: json.donating,
					date: json.date,
					items: json.items,
					totals: json.totals,
					text: json.text,
					subText: json.subText,
					bottomItems: json.bottomItems,
				});
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<Fragment>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="receipt">
					<h3>
						{order.donating === "GcGc"
							? "Thank you for your registration!"
							: order.donating === "GcSp"
							? "Thank you for your sponsorship!"
							: "Thank you for your donation!"}
					</h3>
					<p>{order.text}</p>
					<p>{order.subText}</p>

					<table className="order hide-on-mobile">
						<thead>
							<tr>
								<th>Date</th>
								<th className="left">Order Number {order.id}</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{order.items.map((item, index) => {
								return (
									<tr key={index}>
										<td className="center">
											<span>{order.date}</span>
										</td>
										<td>
											<div>
												<span>{item.item}</span>
												<small>{item.subitem}</small>
											</div>
										</td>
										<td className="right">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							{order.totals.map((item, index) => {
								return (
									item.name && (
										<tr key={index + 10}>
											<td colSpan="2" className="right">
												{item.name}
											</td>
											<td className="right">{item.value}</td>
										</tr>
									)
								);
							})}
						</tfoot>
					</table>

					<table className="order hide-on-desktop">
						<thead>
							<tr>
								<th className="left">
									Order Number {order.id} on {order.date}
								</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{order.items.map((item, index) => {
								return (
									<tr key={index}>
										<td>
											<div>
												<span>{item.item}</span>
												<small>{item.subitem}</small>
											</div>
										</td>
										<td className="right">{item.price}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							{order.totals.map((item, index) => {
								return (
									item.name && (
										<tr key={index + 20}>
											<td className="right">{item.name}</td>
											<td className="right">{item.value}</td>
										</tr>
									)
								);
							})}
						</tfoot>
					</table>

					<div>
						{order.bottomItems.map((item, index) => {
							return <p key={index + 30}>{item}</p>;
						})}
						<p className="small">
							<small>
								<i>
									In accordance with IRS regulations, only the amount
									contributed in excess of benefits received from goods and
									services is considered a charitable contribution. The Nicklaus
									Children’s Health Care Foundation’s EIN# is 57-1154352.
								</i>
							</small>
						</p>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Receipt;
