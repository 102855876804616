import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons";

const Checkbox = ({ note = "", req = true, name, update, data }) => {
  return (
    <div className="checkbox">
      <div>
        {data.map((item, index) => {
          return (
            <label key={index} onClick={e => update(name, item.value)}>
              <FontAwesomeIcon icon={item.checked ? faCheckSquare : faSquare} />
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
      <small className={note ? "" : "hidden"}>{note}</small>
    </div>
  );
};

export default Checkbox;
