import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonIcons = ({ name, value, data, selected, click }) => {
  return (
    <button
      type="button"
      className={selected ? "button selected" : "button"}
      onClick={() => click(name, value)}
    >
      <span>
        {data.map((item, index) => {
          return (
            <span key={index}>
              <FontAwesomeIcon icon={item} size="2x" />
            </span>
          );
        })}
      </span>
    </button>
  );
};

export default ButtonIcons;
