import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

const NoticeText = ({ text }) => {
  return (
    <p className={`notice-text`}>
      <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
      <span>{text}</span>
    </p>
  );
};

export default NoticeText;
