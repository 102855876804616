import { useState, useRef } from "react";
import { API_URL, fmtCurrency, calcFees2 } from "../utils/helpers";

import GenD from "./GenD/index";
import ClF from "./ClF/index";
import RiH from "./RiH/index";
import GaB from "./GaB/index";
import Golf from "./Golf/index";
import GcAc from "./GcAc/index";
import GcGc from "./GcGc/index";
import GcSp from "./GcSp/index";
import JaG from "./JaG/index";
import Contact from "./Contact/index";

import DonateButton from "../components/DonateButton/index";
import Checkbox from "../components/Checkbox/index";
import Msg from "../components/Msg/index";
import Submit from "../components/Submit/index";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Donate = ({ form, status, donating = "" }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		donating:
			form === "donate" && donating
				? donating
				: form === "donate2"
				? "Golf"
				: form === "donate3"
				? "GcAc"
				: form === "donate4" && donating
				? donating
				: form === "donate5"
				? "JaG"
				: "",
		frequency: form === "donate3" ? "Yearly" : "One-time",
		memory: "in memory of",
	});
	const [totals, setTotals] = useState({
		amount: 0,
		addlAmt: 0,
		donation: 0,
		fee: 0,
		total: 0,
	});
	const [msg, setMsg] = useState({});

	const categories = [
		{ value: "WtB", name: "Wear the Bear. Show you Care.", show: false },
		{
			value: "GenD",
			name: "General Donation - In Memory/Honor of",
			show: true,
		},
		{
			value: "ClF",
			name: "Jack & Barbara Nicklaus Children’s Legacy Fund",
			show: true,
		},
		{ value: "RiH", name: "Reading is Healing", show: true },
		{ value: "IgT", name: "“I Got This!”", show: false },
		{ value: "GaB", name: "Give a Bear. Get a Bear.", show: true },
		{ value: "WdC", name: "Well Done! Cookbook", show: false },
	];

	const categories4 = [
		{
			value: "GcGc",
			name: "Registering for the Golden Cub Golf Classic",
			show: true,
		},
		{
			value: "GcSp",
			name: "Sponsorships for the Golden Cub Golf Classic",
			show: false,
		},
	];

	const formElement = useRef(null);

	const handleUpdate = (name, value) => {
		if (name === "intl") {
			setNames((names) => ({ ...names, intl: !names.intl }));
		} else if (name === "fees") {
			handleTotalFees(!names.fees);
			setNames((names) => ({ ...names, fees: !names.fees }));
		} else if (name === "payment") {
			handleTotalFees(false);
			setNames((names) => ({
				...names,
				fees: false,
				[name]: value ? value : "",
			}));
		} else if (name === "acceptRecurring") {
			setNames((names) => ({
				...names,
				acceptRecurring: !names.acceptRecurring,
			}));
		} else if (name === "donating" && form === "donate4") {
			setTotals((totals) => ({
				...totals,
				amount: 0,
				addlAmt: 0,
				donation: 0,
				fee: 0,
				total: 0,
			}));
			setNames((names) => ({
				...names,
				[name]: value ? value : "",
				gcgcType: "member",
				gcgcSponsorship: "",
			}));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleAmount = (int) => {
		const amount = int;
		const addlAmt = totals.addlAmt;
		const donation = amount + addlAmt;
		const fee = 0;
		const total = donation + fee;
		setTotals((totals) => ({
			...totals,
			amount,
			addlAmt,
			donation,
			fee,
			total,
		}));
		setNames((names) => ({ ...names, fees: false }));

		console.log(amount);
	};

	const handleAddlAmt = (int) => {
		const amount = totals.amount;
		const addlAmt = int;
		const donation = amount + addlAmt;
		const fee = 0;
		const total = donation + fee;
		setTotals((totals) => ({
			...totals,
			amount,
			addlAmt,
			donation,
			fee,
			total,
		}));
		setNames((names) => ({ ...names, fees: false }));

		console.log(amount);
	};

	const handleTotalFees = (fees) => {
		const fee = fees ? calcFees2("cc", totals.donation) : 0;
		const total = fee + totals.donation;
		setTotals((totals) => ({ ...totals, fee, total }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=donate`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			if (
				key === "hat1me" ||
				key === "hat2me" ||
				key === "hat3me" ||
				key === "hat4me" ||
				key === "hat5me" ||
				key === "dedicate" ||
				key === "intl" ||
				key === "toIntl" ||
				key === "acceptRecurring" ||
				key === "interested" ||
				key === "dinnerOnly" ||
				key === "addlDinnerOnly"
			) {
				formData.append(key, value ? "1" : "0");
			} else if (key === "gcgcAddlParticipants") {
				formData.append(key, JSON.stringify(value));
			} else {
				formData.append(key, value);
			}
		});
		Object.entries(totals).forEach(([key, value]) => {
			formData.append(key, value || "0");
		});

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const obj = {
					id: json.id,
					customerId: json.customerId,
					subscriptionId: json.subscriptionId,
					clientSecret: json.clientSecret,
				};
				status(0, 2, obj);
			} else if (json && json.resp === 2) {
				status(json.id, 1);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{form === "donate" && <h3>I am Donating to:</h3>}
			{form === "donate4" && <h3>I am interested in:</h3>}
			<form
				ref={formElement}
				method="post"
				action="/"
				onSubmit={(e) => handleSubmit(e)}
			>
				{form === "donate" && (
					<div className="buttons donating">
						{categories.map((item, index) => {
							return (donating === "WtB" && item.value !== "WtB") ||
								!item.show ? null : (
								<DonateButton
									key={index}
									name="donating"
									data={item}
									click={handleUpdate}
									selected={names.donating === item.value ? true : false}
									visibility={
										item.value === "WtB" && donating !== "WtB" ? "" : ""
									}
								/>
							);
						})}
					</div>
				)}

				{form === "donate4" && (
					<div className="buttons donating">
						{categories4.map((item, index) => {
							return (
								<DonateButton
									key={index}
									name="donating"
									data={item}
									click={handleUpdate}
									selected={names.donating === item.value ? true : false}
									visibility=""
								/>
							);
						})}
					</div>
				)}

				{(form === "donate" || form === "donate4") && <p className="line" />}

				{names.donating === "GenD" && (
					<GenD
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "ClF" && (
					<ClF
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "RiH" && (
					<RiH
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "GaB" && (
					<GaB
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "Golf" && (
					<Golf
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "GcAc" && (
					<GcAc
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating === "GcGc" && (
					<GcGc
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
						formElement={formElement}
					/>
				)}

				{names.donating === "GcSp" && (
					<GcSp
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
						formElement={formElement}
					/>
				)}

				{names.donating === "JaG" && (
					<JaG
						total={handleAmount}
						total2={handleAddlAmt}
						update={handleUpdate}
						errorsFromDonate={errors}
					/>
				)}

				{names.donating !== "GcAc" &&
					names.donating !== "GcGc" &&
					names.donating !== "GcSp" &&
					names.donating !== "JaG" && (
						<Contact update={handleUpdate} errorsFromDonate={errors} />
					)}

				<div
					className={
						totals.donation >= 100000 && names.donating !== "WtB"
							? "checkbox"
							: "hidden"
					}
				>
					<Checkbox
						name="fees"
						update={handleUpdate}
						note="For amounts of $1,000 or more, you have the option to cover our processing fees."
						data={[
							{
								value: false,
								label: "Yes, I would like to cover the CC processing fees",
							},
						].map((item) => {
							return {
								value: item.value,
								label: item.label,
								checked: names.fees,
							};
						})}
					/>
				</div>

				<table className="summary">
					<tbody>
						<tr
							className={
								names.donating === "GcGc" || totals.amount > 0 ? "" : "hidden"
							}
						>
							<th>
								{names.donating === "GcAc"
									? "Membership Amount:"
									: names.donating === "GcGc"
									? "Registration Amount:"
									: "Donation Amount:"}
							</th>
							<td className="right">{fmtCurrency(totals.amount)}</td>
						</tr>
						<tr className={totals.addlAmt > 0 ? "" : "hidden"}>
							<th>
								{names.donating === "GcGc" || names.donating === "GcSp"
									? "Sponsorship Amount:"
									: totals.amount === 0
									? "Donation Amount:"
									: "Additional Donation:"}
							</th>
							<td className="right">{fmtCurrency(totals.addlAmt)}</td>
						</tr>
						<tr className={totals.fee > 0 ? "" : "hidden"}>
							<th>Processing Fee:</th>
							<td className="right">{fmtCurrency(totals.fee)}</td>
						</tr>
						<tr>
							<th>Total:</th>
							<td className="right">{fmtCurrency(totals.total)}</td>
						</tr>
					</tbody>
				</table>

				<div
					className={
						names.frequency === "Monthly" || names.frequency === "Yearly"
							? "checkbox"
							: "hidden"
					}
				>
					<Checkbox
						name="acceptRecurring"
						update={handleUpdate}
						note=""
						data={[
							{
								value: false,
								label: `I authorize my payment method provided to billed ${
									names.frequency === "Monthly" ? "monthly" : "annually"
								} for the ${
									names.donating === "GcAc" ? "membership" : "donation"
								} amount.`,
							},
						].map((item) => {
							return {
								value: item.value,
								label: item.label,
								checked: names.acceptRecurring,
							};
						})}
					/>
				</div>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={msg.type === "working" ? "hidden" : "submit-container"}
					>
						<Submit
							name={
								names.donating === "GcGc" &&
								names?.gcgcType === "member" &&
								totals.total === 0
									? "Submit Registration"
									: "Submit & Continue to Payment"
							}
							icon={faChevronCircleRight}
						/>
					</div>
				</div>

				<p>
					<small>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a
							href="/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Policy
						</a>
						.
					</small>
				</p>
			</form>
		</>
	);
};

export default Donate;
