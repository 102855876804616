import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

const Modal = ({ type, id, handleCloseModal }) => {
	let header = null;
	let footer = null;
	if (document.getElementsByClassName("page_header").length > 0) {
		const collection = document.getElementsByClassName("page_header");
		const collection2 = document.getElementsByClassName("footer_inner");
		header = collection[0];
		footer = collection2[0];
		header.style.zIndex = 100;
		footer.style.visibility = "hidden";
	}

	document.body.style.overflow = "hidden";
	document.body.classList.add("modal-body");

	const handleClose = (e) => {
		if (header) {
			header.style.zIndex = 110;
			footer.style.visibility = "visible";
		}

		document.body.style.overflow = "auto";
		document.body.classList.remove("modal-body");
		handleCloseModal();
	};

	return (
		<>
			<div className="modal" onClick={(e) => handleClose(e)}>
				<div className="container">
					<div className="video">
						<button type="button" onClick={(e) => handleClose(e)}>
							<FontAwesomeIcon icon={faTimesCircle} size="lg"></FontAwesomeIcon>
						</button>

						{type === "vimeo" ? (
							<>
								<iframe
									src={`https://player.vimeo.com/video/${id}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479`}
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									title="Crib Event_Final"
								></iframe>
								<script src="https://player.vimeo.com/api/player.js"></script>
							</>
						) : (
							<iframe
								src={`https://www.youtube.com/embed/${id}?rel=0`}
								frameBorder="0"
								allowFullScreen
							></iframe>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
