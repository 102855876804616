export const API_URL = "https://nchcf.org/k-api2";
export const RECAPTCHA_KEY = "6Lf35rYZAAAAAL0QXmtCEslogoocY_hVhVtzLvXD";

export function fmtCurrency(int) {
  const amt = int / 100;
  return `${amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })}`;
}

export function calcFees(payment, str, int) {
  let fee = 0;
  let perc = 0;
  if (!payment) {
  } else {
    perc = 0.029;
  }

  if (int >= 500000) {
    fee = int * perc;
  }
  return Math.round(fee);
}

export function calcFees2(payment, int) {
  let fee = 0;
  let perc = 0;
  if (!payment) {
  } else {
    perc = 0.029;
  }

  if (int >= 50000) {
    fee = int * perc;
  }
  return Math.round(fee);
}

export function scroll() {
  return window.jQuery("html, body").animate(
    {
      scrollTop: window.jQuery("#nchcfform2-root").offset().top - 50,
    },
    200
  );
}

export const COUNTRIES = [
  { value: "", name: "--" },
  { value: "004", name: "Afghanistan" },
  { value: "008", name: "Albania" },
  { value: "010", name: "Antarctica" },
  { value: "012", name: "Algeria" },
  { value: "016", name: "American Samoa" },
  { value: "020", name: "Andorra" },
  { value: "024", name: "Angola" },
  { value: "028", name: "Antigua and Barbuda" },
  { value: "031", name: "Azerbaijan" },
  { value: "032", name: "Argentina" },
  { value: "036", name: "Australia" },
  { value: "040", name: "Austria" },
  { value: "044", name: "Bahamas" },
  { value: "048", name: "Bahrain" },
  { value: "050", name: "Bangladesh" },
  { value: "051", name: "Armenia" },
  { value: "052", name: "Barbados" },
  { value: "056", name: "Belgium" },
  { value: "060", name: "Bermuda" },
  { value: "064", name: "Bhutan" },
  { value: "068", name: "Bolivia (Plurinational State of)" },
  { value: "070", name: "Bosnia and Herzegovina" },
  { value: "072", name: "Botswana" },
  { value: "074", name: "Bouvet Island" },
  { value: "076", name: "Brazil" },
  { value: "084", name: "Belize" },
  { value: "086", name: "British Indian Ocean Territory" },
  { value: "090", name: "Solomon Islands" },
  { value: "092", name: "Virgin Islands (British)" },
  { value: "096", name: "Brunei Darussalam" },
  { value: "100", name: "Bulgaria" },
  { value: "104", name: "Myanmar" },
  { value: "108", name: "Burundi" },
  { value: "112", name: "Belarus" },
  { value: "116", name: "Cambodia" },
  { value: "120", name: "Cameroon" },
  { value: "124", name: "Canada" },
  { value: "132", name: "Cabo Verde" },
  { value: "136", name: "Cayman Islands" },
  { value: "140", name: "Central African Republic" },
  { value: "144", name: "Sri Lanka" },
  { value: "148", name: "Chad" },
  { value: "152", name: "Chile" },
  { value: "156", name: "China" },
  { value: "158", name: "Taiwan, Province of China" },
  { value: "162", name: "Christmas Island" },
  { value: "166", name: "Cocos (Keeling) Islands" },
  { value: "170", name: "Colombia" },
  { value: "174", name: "Comoros" },
  { value: "175", name: "Mayotte" },
  { value: "178", name: "Congo" },
  { value: "180", name: "Congo, Democratic Republic of the" },
  { value: "184", name: "Cook Islands" },
  { value: "188", name: "Costa Rica" },
  { value: "191", name: "Croatia" },
  { value: "192", name: "Cuba" },
  { value: "196", name: "Cyprus" },
  { value: "203", name: "Czechia" },
  { value: "204", name: "Benin" },
  { value: "208", name: "Denmark" },
  { value: "212", name: "Dominica" },
  { value: "214", name: "Dominican Republic" },
  { value: "218", name: "Ecuador" },
  { value: "222", name: "El Salvador" },
  { value: "226", name: "Equatorial Guinea" },
  { value: "231", name: "Ethiopia" },
  { value: "232", name: "Eritrea" },
  { value: "233", name: "Estonia" },
  { value: "234", name: "Faroe Islands" },
  { value: "238", name: "Falkland Islands (Malvinas)" },
  { value: "239", name: "South Georgia and the South Sandwich Islands" },
  { value: "242", name: "Fiji" },
  { value: "246", name: "Finland" },
  { value: "248	Åland Islands" },
  { value: "250", name: "France" },
  { value: "254", name: "French Guiana" },
  { value: "258", name: "French Polynesia" },
  { value: "260", name: "French Southern Territories" },
  { value: "262", name: "Djibouti" },
  { value: "266", name: "Gabon" },
  { value: "268", name: "Georgia" },
  { value: "270", name: "Gambia" },
  { value: "275", name: "Palestine, State of" },
  { value: "276", name: "Germany" },
  { value: "288", name: "Ghana" },
  { value: "292", name: "Gibraltar" },
  { value: "296", name: "Kiribati" },
  { value: "300", name: "Greece" },
  { value: "304", name: "Greenland" },
  { value: "308", name: "Grenada" },
  { value: "312", name: "Guadeloupe" },
  { value: "316", name: "Guam" },
  { value: "320", name: "Guatemala" },
  { value: "324", name: "Guinea" },
  { value: "328", name: "Guyana" },
  { value: "332", name: "Haiti" },
  { value: "334", name: "Heard Island and McDonald Islands" },
  { value: "336", name: "Holy See" },
  { value: "340", name: "Honduras" },
  { value: "344", name: "Hong Kong" },
  { value: "348", name: "Hungary" },
  { value: "352", name: "Iceland" },
  { value: "356", name: "India" },
  { value: "360", name: "Indonesia" },
  { value: "364", name: "Iran (Islamic Republic of)" },
  { value: "368", name: "Iraq" },
  { value: "372", name: "Ireland" },
  { value: "376", name: "Israel" },
  { value: "380", name: "Italy" },
  { value: "384", name: "Côte d'Ivoire" },
  { value: "388", name: "Jamaica" },
  { value: "392", name: "Japan" },
  { value: "398", name: "Kazakhstan" },
  { value: "400", name: "Jordan" },
  { value: "404", name: "Kenya" },
  { value: "408", name: "Korea (Democratic People's Republic of)" },
  { value: "410", name: "Korea, Republic of" },
  { value: "414", name: "Kuwait" },
  { value: "417", name: "Kyrgyzstan" },
  { value: "418", name: "Lao People's Democratic Republic" },
  { value: "422", name: "Lebanon" },
  { value: "426", name: "Lesotho" },
  { value: "428", name: "Latvia" },
  { value: "430", name: "Liberia" },
  { value: "434", name: "Libya" },
  { value: "438", name: "Liechtenstein" },
  { value: "440", name: "Lithuania" },
  { value: "442", name: "Luxembourg" },
  { value: "446", name: "Macao" },
  { value: "450", name: "Madagascar" },
  { value: "454", name: "Malawi" },
  { value: "458", name: "Malaysia" },
  { value: "462", name: "Maldives" },
  { value: "466", name: "Mali" },
  { value: "470", name: "Malta" },
  { value: "474", name: "Martinique" },
  { value: "478", name: "Mauritania" },
  { value: "480", name: "Mauritius" },
  { value: "484", name: "Mexico" },
  { value: "492", name: "Monaco" },
  { value: "496", name: "Mongolia" },
  { value: "498", name: "Moldova, Republic of" },
  { value: "499", name: "Montenegro" },
  { value: "500", name: "Montserrat" },
  { value: "504", name: "Morocco" },
  { value: "508", name: "Mozambique" },
  { value: "512", name: "Oman" },
  { value: "516", name: "Namibia" },
  { value: "520", name: "Nauru" },
  { value: "524", name: "Nepal" },
  { value: "528", name: "Netherlands" },
  { value: "531", name: "Curaçao" },
  { value: "533", name: "Aruba" },
  { value: "534", name: "Sint Maarten (Dutch part)" },
  { value: "535", name: "Bonaire, Sint Eustatius and Saba" },
  { value: "540", name: "New Caledonia" },
  { value: "548", name: "Vanuatu" },
  { value: "554", name: "New Zealand" },
  { value: "558", name: "Nicaragua" },
  { value: "562", name: "Niger" },
  { value: "566", name: "Nigeria" },
  { value: "570", name: "Niue" },
  { value: "574", name: "Norfolk Island" },
  { value: "578", name: "Norway" },
  { value: "580", name: "Northern Mariana Islands" },
  { value: "581", name: "United States Minor Outlying Islands" },
  { value: "583", name: "Micronesia (Federated States of)" },
  { value: "584", name: "Marshall Islands" },
  { value: "585", name: "Palau" },
  { value: "586", name: "Pakistan" },
  { value: "591", name: "Panama" },
  { value: "598", name: "Papua New Guinea" },
  { value: "600", name: "Paraguay" },
  { value: "604", name: "Peru" },
  { value: "608", name: "Philippines" },
  { value: "612", name: "Pitcairn" },
  { value: "616", name: "Poland" },
  { value: "620", name: "Portugal" },
  { value: "624", name: "Guinea-Bissau" },
  { value: "626", name: "Timor-Leste" },
  { value: "630", name: "Puerto Rico" },
  { value: "634", name: "Qatar" },
  { value: "638", name: "Réunion" },
  { value: "642", name: "Romania" },
  { value: "643", name: "Russian Federation" },
  { value: "646", name: "Rwanda" },
  { value: "652", name: "Saint Barthélemy" },
  { value: "654", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "659", name: "Saint Kitts and Nevis" },
  { value: "660", name: "Anguilla" },
  { value: "662", name: "Saint Lucia" },
  { value: "663", name: "Saint Martin (French part)" },
  { value: "666", name: "Saint Pierre and Miquelon" },
  { value: "670", name: "Saint Vincent and the Grenadines" },
  { value: "674", name: "San Marino" },
  { value: "678", name: "Sao Tome and Principe" },
  { value: "682", name: "Saudi Arabia" },
  { value: "686", name: "Senegal" },
  { value: "688", name: "Serbia" },
  { value: "690", name: "Seychelles" },
  { value: "694", name: "Sierra Leone" },
  { value: "702", name: "Singapore" },
  { value: "703", name: "Slovakia" },
  { value: "704", name: "Viet Nam" },
  { value: "705", name: "Slovenia" },
  { value: "706", name: "Somalia" },
  { value: "710", name: "South Africa" },
  { value: "716", name: "Zimbabwe" },
  { value: "724", name: "Spain" },
  { value: "728", name: "South Sudan" },
  { value: "729", name: "Sudan" },
  { value: "732", name: "Western Sahara" },
  { value: "740", name: "Suriname" },
  { value: "744", name: "Svalbard and Jan Mayen" },
  { value: "748", name: "Eswatini" },
  { value: "752", name: "Sweden" },
  { value: "756", name: "Switzerland" },
  { value: "760", name: "Syrian Arab Republic" },
  { value: "762", name: "Tajikistan" },
  { value: "764", name: "Thailand" },
  { value: "768", name: "Togo" },
  { value: "772", name: "Tokelau" },
  { value: "776", name: "Tonga" },
  { value: "780", name: "Trinidad and Tobago" },
  { value: "784", name: "United Arab Emirates" },
  { value: "788", name: "Tunisia" },
  { value: "792", name: "Turkey" },
  { value: "795", name: "Turkmenistan" },
  { value: "796", name: "Turks and Caicos Islands" },
  { value: "798", name: "Tuvalu" },
  { value: "800", name: "Uganda" },
  { value: "804", name: "Ukraine" },
  { value: "807", name: "North Macedonia" },
  { value: "818", name: "Egypt" },
  {
    value: "826",
    name: "United Kingdom of Great Britain and Northern Ireland",
  },
  { value: "831", name: "Guernsey" },
  { value: "832", name: "Jersey" },
  { value: "833", name: "Isle of Man" },
  { value: "834", name: "Tanzania, United Republic of" },
  { value: "840", name: "United States of America" },
  { value: "850", name: "Virgin Islands (U.S.)" },
  { value: "854", name: "Burkina Faso" },
  { value: "858", name: "Uruguay" },
  { value: "860", name: "Uzbekistan" },
  { value: "862", name: "Venezuela (Bolivarian Republic of)" },
  { value: "876", name: "Wallis and Futuna" },
  { value: "882", name: "Samoa" },
  { value: "887", name: "Yemen" },
  { value: "894", name: "Zambia" },
];
