import { useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import Msg from "../../components/Msg/index";
import "./styles.css";

const OtherPay = ({ data, total, success, msgFromPayment, update }) => {
  const [msg, setMsg] = useState({
    type: "working",
    text: "Checking for other available payment methods.",
  });
  const [paymentRequest, setPaymentRequest] = useState(null);

  const stripe = useStripe();

  useEffect(() => {
    if (msgFromPayment.type) {
      setMsg(msgFromPayment);
    }
  }, [msgFromPayment]);

  useEffect(() => {
    if (stripe && total) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Donation Total",
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });

      pr.canMakePayment().then((result) => {
        if (result && (result?.applePay || result?.googlePay)) {
          update("otherPay", result.applePay ? "applePay" : "googlePay");
          setPaymentRequest(pr);
          setMsg({});
        } else {
          setMsg({
            type: "error",
            text: "No other payment methods are available, please use credit or debit card.",
          });
        }
      });
    }

    // eslint-disable-next-line
  }, [stripe, total]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", async (ev) => {
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            data.clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

        if (confirmError) {
          setMsg({
            type: "error",
            text: "There was an error completing your payment.",
          });
          ev.complete("fail");
        } else {
          ev.complete("success");
          if (paymentIntent.status === "requires_action") {
            const { error } = await stripe.confirmCardPayment(
              data.clientSecret
            );
            if (error) {
              setMsg({
                type: "error",
                text: "There was an error completing your payment.",
              });
            } else {
              success({
                ...data,
                paymentIntentId: paymentIntent.id,
                status: paymentIntent.status,
                paymentMethod: paymentIntent.payment_method,
                amount: paymentIntent.amount,
                name: ev.payerName || "",
                email: ev.payerEmail || "",
                phone: ev.payerPhone || "",
              });
            }
          } else {
            success({
              ...data,
              paymentIntentId: paymentIntent.id,
              status: paymentIntent.status,
              paymentMethod: paymentIntent.payment_method,
              amount: paymentIntent.amount,
              name: ev.payerName || "",
              email: ev.payerEmail || "",
              phone: ev.payerPhone || "",
            });
          }
        }
      });
    }

    // eslint-disable-next-line
  }, [paymentRequest]);

  return (
    <>
      {!msg.type && paymentRequest ? (
        <div style={{ marginTop: "7.5px" }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      ) : (
        <div className="msg-submit">
          <Msg data={msg} />
        </div>
      )}
    </>
  );
};

export default OtherPay;
