import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Donate from "./Donate/index";
import Payment from "./Payment/index";
import Receipt from "./Receipt/index";

const stripePromise = loadStripe("pk_live_jCI4RIxA703HQbmgpueFJ9kP");
/*
  pk_test_8f09144xoUKvJk1CSC3W3r6e
  pk_live_jCI4RIxA703HQbmgpueFJ9kP
*/

function App({ form, donating, closed = false }) {
	const [id, setId] = useState(0);
	const [paymentData, setPaymentData] = useState({});
	const [status, setStatus] = useState(closed ? 4 : 3);

	/*
    0 - error
    1 - receipt page (donation details & payment successfully submitted)
    2 - payment page (cc, applePay, GPay)
    3 - donate page (not yet started)
    4 - closed
  */

	const handleStatus = (id, status, paymentData = {}) => {
		setId(id);
		setStatus(status);
		setPaymentData(paymentData);
	};

	return (
		<>
			{id && status === 1 ? (
				<Receipt id={id} />
			) : paymentData && status === 2 ? (
				<Elements stripe={stripePromise}>
					<Payment status={handleStatus} data={paymentData} />
				</Elements>
			) : (
				<Donate form={form} status={handleStatus} donating={donating} />
			)}
		</>
	);
}

export default App;
