import { useState } from "react";

import Vote from "./Vote";
import Thanks from "./Thanks";

function GCVoting() {
	const [id, setId] = useState(0);
	const [votedData, setVotedData] = useState({});
	const [status, setStatus] = useState(2);

	/*
    0 - error
    1 - thanks page (votes successfully submitted)
    2 - voting page (not yet started)
  */

	const handleStatus = (id, status, data = {}) => {
		setId(id);
		setStatus(status);
		setVotedData(data);
	};

	return (
		<>
			{id && status === 1 ? (
				<Thanks id={id} data={votedData} />
			) : (
				<Vote handleStatus={handleStatus} />
			)}
		</>
	);
}

export default GCVoting;
