import { useState } from "react";
import ButtonIcons from "../../components/ButtonIcons/index";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcApplePay,
  faGooglePay,
} from "@fortawesome/free-brands-svg-icons";

const PaymentMethod = ({ update }) => {
  const [names, setNames] = useState({
    payment: "cc",
  });

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
    update(name, value);
  };

  return (
    <>
      <h3 style={{ marginBottom: "15px" }}>Payment Method</h3>

      <div className="payment-method-buttons">
        <ButtonIcons
          name="payment"
          value="cc"
          data={[faCcVisa, faCcMastercard, faCcAmex, faCcDiscover]}
          click={handleUpdate}
          selected={names.payment === "cc" ? true : false}
        />
        <ButtonIcons
          name="payment"
          value="other"
          data={[faCcApplePay, faGooglePay]}
          click={handleUpdate}
          selected={names.payment === "other" ? true : false}
        />
      </div>
    </>
  );
};

export default PaymentMethod;
