import React, { useEffect } from "react";

import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTimesCircle,
	faCheckCircle,
	faExclamationCircle,
	faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";

const Alert = ({ data }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={`alert ${data.type}`}>
			{data.type === "error" ? (
				<FontAwesomeIcon icon={faTimesCircle} size="2x" />
			) : data.type === "success" ? (
				<FontAwesomeIcon icon={faCheckCircle} size="2x" />
			) : data.type === "notice" ? (
				<FontAwesomeIcon icon={faExclamationCircle} size="2x" />
			) : (
				<FontAwesomeIcon icon={faSpinnerThird} size="2x" spin />
			)}
			<span>{data.text}</span>
		</div>
	);
};

export default Alert;
